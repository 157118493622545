import _circle from "./circle";
var exports = {};

function __export(m) {
  for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
var circle_1 = _circle;
exports.default = circle_1.Circle;

__export(_circle);

export default exports;
export const __esModule = exports.__esModule;